<template>
    <div>
        <div class="ol-dashboard__order review-container">
            <ConfirmPopup title="Selesaikan Pesanan ? " id="popup-complete" description="Dengan klik Pesanan Sesuai, dana akan diteruskan kepenjual. Pastikan kamu sudah menerima barang yang sesuai dengan pesananmu.">
                <a :href="wa" target="_blank" class="ol-btn ol-btn--third">Hubungi Kami</a>
                <a v-if="!loadingComplete" @click="onComplete()" class="ol-btn">Pesanan Sesuai</a>
                <a v-else class="ol-btn" style="pointer-events: none;"> &emsp;<i class="fas fa-spinner fa-pulse ol-fa"></i> Pesanan Sesuai</a>
            </ConfirmPopup>

            <div class="review-card">
                <div>
                    <h4 class="ol-dashboard__container-title">Rincian Komplain Pesananmu</h4>
                </div>

                <div>
                    <div class="ol-dashboard__order-address komplainOrderOverview">
                            <div class="ol-dashboard__order-address-item">
                                <h3 class="ol-dashboard__order-address-item-title">Alamat Pengiriman</h3>
                                <h4 class="ol-dashboard__order-address-item-name">
                                    {{ order.receiver_name }} - {{ order.r_more_transaction_order_details[0].receipt_number }}
                                </h4>
                                <p class="ol-dashboard__order-address-item-desc">
                                    {{ order.receiver_phone }}<br>
                                    {{ order.full_address }}
                                </p>
                            </div>
                            <div class="ol-dashboard__order-address-item ">
                                <h3 class="ol-dashboard__order-address-item-title">Total Belanja</h3>
                                <h4 class="ol-dashboard__order-address-item-name">
                                    {{ order.total_rp }}
                                    
                                </h4>
                                
                            </div>
                            <div class="ol-dashboard__order-address-item " v-if="order.r_more_complain.solution">
                                <h3 class="ol-dashboard__order-address-item-title" ><b style="color:#C13B36">Solusi Komplain</b></h3>
                                <h4 class="ol-dashboard__order-address-item-name" style="color:#354B7A;">
                                    {{ order.r_more_complain.solution }}
                                </h4>
                            </div>
                            <br><br>
                            <a style="float:right;" :href="wa" target="_blank"><span style="color:#354B7A;">Hubungi Kami</span> untuk informasi lebih lanjut.</a> 

                    </div>
                        
                </div>
                    <form>
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">Alasan Komplain </label>
                            <select class="form-control" id="exampleFormControlSelect1" disabled>
                                <option selected>{{order.r_more_complain.reason}}</option>
                            </select>
                        </div>
                    </form>
                    <div class="review-layout">
                        <div class="review-section">
                            <div class="reviews">
                                <div v-for="item in products" :key="'complain-detail-'+item.id" class="review-wrapper">
                                    <div class="review-user">
                                        <div class="row">
                                            <div class="user-profile">
                                                <img v-if="item.r_uni_product_variant.r_uni_product_image" :srcset="item.r_uni_product_variant.r_uni_product_image.image_url">
                                                <img v-else :srcset="noImage()">
                                                <p style="text-align: center;"></p>
                                            </div>
                                            <div class="comment-content col">

                                                <div class="row comment-review">
                                                    <div class="starrow" style="color:black;">
                                                        <b>{{item.r_uni_product_variant.name_long}}</b>
                                                        <!-- <i v-for="star in review.rating" :key="'star-'+review.id+'-'+star" class="fas fa-star"></i> -->
                                                    </div>
                                                    <div class="comment-date">
                                                        <p></p>
                                                    </div>
                                                </div>
                                                <div class="comment-detail">
                                                    <p> 
                                                        <!-- {{ item.r_more_complain_products.note }}<br> -->
                                                    </p>
                                                    <p class="comment-variant">{{ item.r_more_complain_products.note }} </p>
                                                    <div class="row image-review">
                                                        <img v-for="image in item.r_more_complain_products.r_more_complain_product_images" :key="'review-image-'+image.id" :srcset="image.image_url">
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
            </div>
            
            <div>
                <router-link style="float:left;font-size: 13px;margin-top:0px;" :to="'/profile/order/'+order.ordersn" class="ol-btn ol-btn--blue">Detail Pesanan </router-link>
                <a v-if="order.r_more_complain.solution" class="ol-btn" style="float:right;font-size: 13px;margin-top:0px;" @click="showConfirmPopup('popup-complete')">Solusi Diterima</a>
            </div>  
        </div>

    <!-- CHATS -->
        <div class="clearfix"></div>
        <hr>
        <div class="chat" style="margin-top:50px">
            <div class="chat-header clearfix" style="border-top: 2px solid #f4f7f6;">
                <div class="row">
                    <div class="col-lg-6">
                        <a href="javascript:void(0);" data-toggle="modal" data-target="#view_info">
                            <img  :srcset="assets('morelogo_nd.png')" alt="avatar">
                        </a>
                        <div class="chat-about">
                            <h6 class="m-b-0">MORE</h6>
                            <!-- <small>Last seen: 2 hours ago</small> -->
                        </div>
                    </div>
                    <div class="col-lg-6 hidden-sm text-right" style="display: none;">
                        <a href="javascript:void(0);" class="btn btn-outline-secondary"><i class="fa fa-camera"></i></a>
                        
                        <input type="file" id="upload-photo">
                        <a for="upload-photo" href="javascript:void(0);" class="btn btn-outline-primary dz-message" data-dz-message>
                            
                            <i class="fa fa-image"></i></a>
                        
                        <!-- <a href="javascript:void(0);" class="btn btn-outline-info"><i class="fa fa-cogs"></i></a> -->
                        <a href="javascript:void(0);" class="btn btn-outline-warning"><i class="fa fa-question"></i></a>
                    </div>
                </div>
            </div>
            <form  id="chat-history" class="chat-history" ref="list" @scroll="initScroll">
                <ul class="m-b-0" id="m-b-0" >
                    <div v-if="loading_more" style="text-align: center;" >
                        <img :src="require('../../assets/loading.gif')" width="40px" />
                    </div>
                    <template v-for="item in data.slice().reverse()"  >
                        <template v-if="item.r_uni_products">
                            <li :key="item.id" class="clearfix">
                                <div v-if="!item.admin_users_id" class="message-data text-right">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                                </div>
                                <div v-else class="message-data">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                </div>
                                <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                                <div v-else class="message my-message">{{ item.text }}</div>
                            </li>
                            <li :key="item.id+'_p'" class="clearfix">
                                <div class="message-data text-right">
                                </div>
                                <div class="message other-message float-right proddetail">
                                    <img :srcset="item.r_uni_products.r_uni_product_images[0].image_url">
                                    <div>
                                        <h3 class="ol-home-product__content-item-list--item-title">
                                            <router-link :to="'/product/'+item.r_uni_products.slug">
                                                {{item.r_uni_products.name}}
                                            </router-link>
                                        </h3>
                                        <div class="ol-home-product__content-item-list--item-price">
                                            <span v-if="item.r_uni_products.discount_max && item.r_uni_products.discount_max != '0%' && item.r_uni_products.price_normal != 'Rp0' " class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_products.price_normal}}</span>
                                            <small class="ol-home-product__content-item-list--item-price-discount">{{item.r_uni_products.price_range}}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </template>
                        <template v-else-if="item.file">
                            <li v-if="item.text" :key="item.id" class="clearfix">
                                <div v-if="!item.admin_users_id" class="message-data text-right">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                                </div>
                                <div v-else class="message-data">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                </div>
                                <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                                <div v-else class="message my-message">{{ item.text }}</div>
                            </li>
                            <li :key="item.id+'_g'" class="clearfix">
                                <div v-if="!item.admin_users_id" class="message-data text-right">
                                </div>
                                <div v-else class="message-data">
                                </div>
                                <div v-if="!item.admin_users_id" class="message other-message float-right imgsend">
                                    <a :href="item.file" target="_blank">
                                        <img :srcset="item.file">
                                    </a>
                                    
                                </div>
                                <div v-else class="message other-message imgsend">
                                    <a :href="item.file" target="_blank">
                                        <img :srcset="item.file">
                                    </a>
                                </div>
                            </li>
                            
                        </template>
                        <template v-else>
                            <li :key="item.id" class="clearfix">
                                <div v-if="!item.admin_users_id" class="message-data text-right">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                    <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar">
                                </div>
                                <div v-else class="message-data">
                                    <span class="message-data-time">{{item.created_at}}</span>
                                </div>
                                <div v-if="!item.admin_users_id" class="message other-message float-right"> {{ item.text }}</div>
                                <div v-else class="message my-message">{{ item.text }}</div>
                            </li>
                        </template>
                        
                    </template>
                        
                    
                    
                    
                </ul>
            </form>
            <div class="chat-message clearfix">
                <div class="input-group mb-0">
                    <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-paper-plane"></i></span>
                    </div>
                    <input type="text" class="form-control" placeholder="Enter text here..." v-on:keyup.enter="onEnter" v-model="msg">                                    
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import globalUrl from '@/util/globallUrl'
import ConfirmPopup from '@/components/popup/ConfirmPopup.vue'
import { mapGetters, mapActions } from "vuex";
const ORDER = globalUrl.WS_PROFILE.ORDER;
export default {
    name:"OrderComplainDetail",
    data(){
        return{
            order: {},
            products:[],
            loadingComplete: false,
            wa: "https://api.whatsapp.com/send/?",
            
            //CHAT
            loading: true,
            loading_more:false,
            load_more:false,
            history_scrollTop:0,
            data:[],
            page:1,
            per_page:10,
            msg: '',
            readQueued:null,
        }
    },
    components:{
        ConfirmPopup,
    },
    computed:{
        ...mapGetters('home',['allSetting']),
    },
    methods:{
        ...mapActions('home',['getSetting']),
        ...mapActions("chats", ["fetchData",'saveChat','read']),
        showConfirmPopup(id = null, show = true) {
            show ? ConfirmPopup.methods.show(id) : ConfirmPopup.methods.hide(id);
        },
        async getDetailOrder(){
            await ORDER.GET_DETAIL(this.order.ordersn).then(response => {
                localStorage.setItem("detailOrder",JSON.stringify(response.data.data));
                this.order = JSON.parse(localStorage.getItem("detailOrder")).order;
            }).catch(error => {
                return false;
            });
        },
        async onComplete(){
            this.loadingComplete = true;
            await ORDER.COMPLETE(this.order.ordersn).then(response => {
                this.$router.push("/profile/order/"+this.order.ordersn);
            }).catch(error => {
                this.$toast.open({ message: "Gagal menerima pesanan, coba lagi nanti!", type: "danger", duration: 5000, });
            });
            this.loadingComplete = false;
        },
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        scrollToEnd: function () {
            // scroll to the start of the last message
            var container = this.$el.querySelector("#m-b-0");
            // console.log(container.scrollHeight);

            this.$refs.list.scrollTop =   container.scrollHeight;
        },
        initScroll(){
            var vm = this;
            if($(".chat-history").scrollTop() ===0) {
                var container = this.$el.querySelector("#m-b-0");
                this.history_scrollTop = container.scrollHeight;
                // console.log(vm.load_more); 
                if(vm.load_more){
                    vm.page++;
                    vm.loading_more =true;
                    vm.getData();
                }
                
            }
        },
        onEnter: function() {
            this.scrollToEnd();
            // console.log(this.msg);
            let pesan = this.msg;
            this.msg = '';
            if( pesan != ''){
                this.saveChat({text: pesan, more_complains_id: this.order.r_more_complain.id}).then(result =>{
                    let response = result.data;
                    this.data = [...[response],...this.data];
                    this.scrollToEnd();

                }).catch(error =>{
                    // console.log(error);
                });
            }
        },
        async getData(){
            this.loading=true;
            this.jqScrolling2top();
            let data={
                page: this.page,
                per_page:this.per_page,
                more_complains_id:this.order.r_more_complain.id,
            };
            this.fetchData(data).then((result) =>{
                this.loading = false;
                this.loading_more = false;
                let response = result.data;
                this.load_more = response.next_page_url ? true: false;
                this.data = [...this.data,...response.data];
                // console.log(this.data);
                if(this.page != 1){
                    var container = this.$el.querySelector("#m-b-0");
                    this.$refs.list.scrollTop = container.scrollHeight - this.history_scrollTop;
                }
                
            }).catch((error)=>{
                this.loading = false;
                // console.log(error);
            });
        },
        queueRead(usingQueue = true){
            if(this.readQueued != null) clearTimeout(this.readQueued);
            if(usingQueue){
                this.readQueued = setTimeout(async () => {
                    this.read({more_complains_id: this.order.r_more_complain.id});
                }, 500);
            }else{
                this.read({more_complains_id: this.order.r_more_complain.id});
            }
        },
    },
    
    updated() {
        // whenever data changes and the component re-renders, this is called.
        this.$nextTick(() => {if(this.page == 1) this.scrollToEnd()});
        this.queueRead();
    },
    created(){
        this.order = JSON.parse(localStorage.getItem("detailOrder")).order;
        this.products = _.filter(this.order.r_more_transaction_order_details[0].r_more_transaction_order_products,(q)=> q.r_more_complain_products );
       
    },
    async mounted(){
        this.getDetailOrder();
        await this.getData();
        await this.getSetting();

        let sendWa = {
            "phone":this.allSetting.wa_no,
            "text":"No.Pesanan: "+this.order.ordersn+", Komplain: "+this.order.r_more_complain.reason,
        };
        this.wa = this.wa+$.param(sendWa);
    },
    beforeRouteLeave(to, from, next){
        this.showConfirmPopup('popup-complete',false);
        next();
    },
}
</script>
<style lang="css" scoped>
    @import '../../assets/css/bootstrap.css';
    @import '../../assets/css/ulasan.css';
    @import '../../assets/css/productdetailnew.css';
    @import '../../assets/css/chat.css';
</style>