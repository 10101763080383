<template>
    <div :class="'ol-popup ol-popup--confirm pop--confirm--'+id ">
        <div class="ol-popup-wrapper ol-popup-wrapper--large ol-pop-up-wrapper-confirm">
            <div class="ol-popup-box ol-pop-up-box-confirm">
                <a class="ol-popup-box__close" @click="hide(id)">
                    <i class="ol-fa fas fa-times"></i>
                </a>
                <div style="text-align: center;">
                    <h4>{{ title }}</h4>
                    <p>{{ description }}</p>
                </div>
                    <div class="row" style=" margin-top: 20px; display: flex; justify-content: center; flex-wrap: wrap; align-items: center; gap: 20px;">
                        <slot></slot>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:"Popup",
    props:[
        "title",
        "id",
        "description",

    ],
    
    methods:{
        show(ID_AS_PARAM=null) {
            let popup_class = ID_AS_PARAM ? "pop--confirm--"+ID_AS_PARAM:'ol-popup--confirm';
            document.getElementsByClassName(popup_class)[0].classList.add("show"), document.querySelector("body").classList.add("popup");
		},
        hide(ID_AS_PARAM=null){
            let popup_class = ID_AS_PARAM ? "pop--confirm--"+ID_AS_PARAM:'ol-popup--confirm';
            document.getElementsByClassName(popup_class)[0].classList.remove("show"), document.querySelector("body").classList.remove("popup");
        }
    },

}
</script>

<style lang="css" scoped>
    .ol-pop-up-wrapper-confirm {
        max-width: 500px;
    }
</style>